import { Table } from "@mantine/core";

const nutrientIds = {
  calories: 1008,
  totalFat: 1004,
  saturatedFat: 1258,
  transFat: 1257,
  cholesterol: 1253,
  sodium: 1093,
  totalCarbohydrate: 1005,
  dietaryFiber: 1079,
  totalSugar: 2000,
  addedSugar: 1235,
  protein: 1003,
  vitaminD: 1110,
  calcium: 1087,
  iron: 1089,
  potassium: 1092,
};

export const mapNutrientsAndReturnLabel = (food) => {
  const foodNutrients = food.foodNutrients;

  const labelObject = {
    calories: 0,
    totalFat: 0,
    saturatedFat: 0,
    transFat: 0,
    cholesterol: 0,
    sodium: 0,
    totalCarbohydrate: 0,
    dietaryFiber: 0,
    totalSugar: 0,
    addedSugar: 0,
    protein: 0,
    vitaminD: 0,
    calcium: 0,
    iron: 0,
    potassium: 0,
  };

  foodNutrients.map((item) => {
    const id = item.nutrientId;
    const amount = item.value;

    switch (id) {
      case nutrientIds.calories:
        labelObject.calories = amount;
        break;
      case nutrientIds.totalFat:
        labelObject.totalFat = amount;
        break;
      case nutrientIds.saturatedFat:
        labelObject.saturatedFat = amount;
        break;
      case nutrientIds.transFat:
        labelObject.transFat = amount;
        break;
      case nutrientIds.cholesterol:
        labelObject.cholesterol = amount;
        break;
      case nutrientIds.sodium:
        labelObject.sodium = amount;
        break;
      case nutrientIds.totalCarbohydrate:
        labelObject.totalCarbohydrate = amount;
        break;
      case nutrientIds.dietaryFiber:
        labelObject.dietaryFiber = amount;
        break;
      case nutrientIds.totalSugar:
        labelObject.totalSugar = amount;
        break;
      case nutrientIds.addedSugar:
        labelObject.addedSugar = amount;
        break;
      case nutrientIds.protein:
        labelObject.protein = amount;
        break;
      case nutrientIds.vitaminD:
        labelObject.vitaminD = amount;
        break;
      case nutrientIds.calcium:
        labelObject.calcium = amount;
        break;
      case nutrientIds.iron:
        labelObject.iron = amount;
        break;
      case nutrientIds.potassium:
        labelObject.potassium = amount;
        break;
      default:
        break;
    }
    return;
  });

  //Source: https://ods.od.nih.gov/HealthInformation/nutrientrecommendations.aspx
  //Add objects for Infants through 12 months, Children 1-3 years, pregnant women and lactating women
  // const dailyValues = [
  //   {group: "adults", description: "Adults and Children >= 4 years", values: {
  //   totalFat: 78,
  //   saturatedFat: 20,
  //   transFat: 0,
  //   cholesterol: 300,
  //   sodium: 2300,
  //   totalCarbohydrate: 275,
  //   dietaryFiber: 28,
  //   totalSugar: 0,
  //   addedSugar: 50,
  //   protein: 50,
  //   vitaminD: 20,
  //   calcium: 1300,
  //   iron: 18,
  //   potassium: 4700,
  //   }},
  //   {group: "infants", description: "Infants through 12 months", values: {
  //     totalFat: 30,
  //     saturatedFat: "NA",
  //     transFat: "NA",
  //     cholesterol: "NA",
  //     sodium: "NA",
  //     totalCarbohydrate: 95,
  //     dietaryFiber: "NA",
  //     totalSugar: "NA",
  //     addedSugar: "NA",
  //     protein: "NA",
  //     vitaminD: "NA",
  //     calcium: "NA",
  //     iron: "NA",
  //     potassium: "NA",
  //   }},
  //   {group: "children", description: "Children 1-3 years", values: {
  //     totalFat: 39,
  //     saturatedFat: 10,
  //     transFat: 0,
  //     cholesterol: 300,
  //     sodium: 1500,
  //     totalCarbohydrate: 150,
  //     dietaryFiber: 14,
  //     totalSugar: 0,
  //     addedSugar: 25,
  //     protein: 13,
  //     vitaminD: 0,
  //     calcium: 0,
  //     iron: 0,
  //     potassium: 0,
  //   }},
  //   {group: "pregnant", description: "Pregnant women and lactating women", values: {
  //     totalFat: 78,
  //     saturatedFat: 20,
  //     transFat: 0,
  //     cholesterol: 300,
  //     sodium: 2300,
  //     totalCarbohydrate: 275,
  //     dietaryFiber: 28,
  //     totalSugar: 0,
  //     addedSugar: 50,
  //     protein: 50,
  //     vitaminD: 20,
  //     calcium: 1300,
  //     iron: 18,
  //     potassium: 4700,
  //   }}
  // ]

  const dailyValue = {
    totalFat: 78,
    saturatedFat: 20,
    transFat: 0,
    cholesterol: 300,
    sodium: 2300,
    totalCarbohydrate: 275,
    dietaryFiber: 28,
    totalSugar: 0,
    addedSugar: 50,
    protein: 50,
    vitaminD: 20,
    calcium: 1300,
    iron: 18,
    potassium: 4700,
  };

  //Create an object of daily value percentages

  const dailyValuePercentages = {
    totalFat: Math.round((labelObject.totalFat / dailyValue.totalFat) * 100),
    saturatedFat: Math.round(
      (labelObject.saturatedFat / dailyValue.saturatedFat) * 100
    ),
    transFat: Math.round((labelObject.transFat / dailyValue.transFat) * 100),
    cholesterol: Math.round(
      (labelObject.cholesterol / dailyValue.cholesterol) * 100
    ),
    sodium: Math.round((labelObject.sodium / dailyValue.sodium) * 100),
    totalCarbohydrate: Math.round(
      (labelObject.totalCarbohydrate / dailyValue.totalCarbohydrate) * 100
    ),
    dietaryFiber: Math.round(
      (labelObject.dietaryFiber / dailyValue.dietaryFiber) * 100
    ),
    totalSugar: Math.round(
      (labelObject.totalSugar / dailyValue.totalSugar) * 100
    ),
    addedSugar: Math.round(
      (labelObject.addedSugar / dailyValue.addedSugar) * 100
    ),
    protein: Math.round((labelObject.protein / dailyValue.protein) * 100),
    vitaminD: Math.round((labelObject.vitaminD / dailyValue.vitaminD) * 100),
    calcium: Math.round((labelObject.calcium / dailyValue.calcium) * 100),
    iron: Math.round((labelObject.iron / dailyValue.iron) * 100),
    potassium: Math.round((labelObject.potassium / dailyValue.potassium) * 100),
  };

  return (
    <>
      <Table
        style={{ width: "300px" }}
        verticalSpacing="12px"
        horizontalSpacing="xl"
        highlightOnHover
      >
        <thead>
          <tr>
            <th colSpan="2">
              <h1 style={{ color: "black", margin: "0px 0px 4px 0px" }}>
                Nutrition Facts
              </h1>
              <p style={{ margin: "0px", fontWeight: "normal" }}>
                Serving Size: {food.servingSize}
                {food.servingSizeUnit}
              </p>
              <p style={{ margin: "0px 0px 2px 0px", fontWeight: "normal" }}>
                Package Weight: {food.packageWeight}
              </p>
            </th>
          </tr>
          <tr></tr>
        </thead>

        <tbody>
          <tr>
            <td style={{ fontSize: "20px" }}>
              <b>Calories</b>
            </td>
            <td style={{ fontSize: "30px", textAlign: "right" }}>
              <b>{labelObject.calories}</b>
            </td>
          </tr>
          <tr>
            <td></td>
            <td style={{ textAlign: "right" }}>
              <b>% Daily Value</b>
            </td>
          </tr>
          <tr>
            <td>
              <b>Total Fat</b> {Math.round(labelObject.totalFat)}g
            </td>
            <td style={{ textAlign: "right" }}>
              <b>{dailyValuePercentages.totalFat}%</b>
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: "25px" }}>
              Saturated Fat {Math.round(labelObject.saturatedFat)}g
            </td>
            <td style={{ textAlign: "right" }}>
              <b>{dailyValuePercentages.saturatedFat}%</b>
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: "25px" }}>
              <i>Trans</i> Fat {Math.round(labelObject.transFat)}g
            </td>
            <td style={{ textAlign: "right" }}>
              <b></b>
            </td>
          </tr>
          <tr>
            <td>
              <b>Cholesterol</b> {Math.round(labelObject.cholesterol)}mg
            </td>
            <td style={{ textAlign: "right" }}>
              <b>{dailyValuePercentages.cholesterol}%</b>
            </td>
          </tr>
          <tr>
            <td>
              <b>Sodium</b> {Math.round(labelObject.sodium)}mg
            </td>
            <td style={{ textAlign: "right" }}>
              <b>{dailyValuePercentages.sodium}%</b>
            </td>
          </tr>
          <tr>
            <td>
              <b>Total Carbohydrate</b>{" "}
              {Math.round(labelObject.totalCarbohydrate)}g
            </td>
            <td style={{ textAlign: "right" }}>
              <b>{dailyValuePercentages.totalCarbohydrate}%</b>
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: "25px" }}>
              Dietary Fiber {Math.round(labelObject.dietaryFiber)}g
            </td>
            <td style={{ textAlign: "right" }}>
              <b>{dailyValuePercentages.dietaryFiber}%</b>
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: "25px" }}>
              Total Sugar {Math.round(labelObject.totalSugar)}g
            </td>
            <td style={{ textAlign: "right" }}>
              <b></b>
            </td>
          </tr>
          <tr>
            <td style={{ paddingLeft: "50px" }}>
              Added Sugar {Math.round(labelObject.addedSugar)}g
            </td>
            <td style={{ textAlign: "right" }}>
              <b>{dailyValuePercentages.addedSugar}%</b>
            </td>
          </tr>
          <tr>
            <td>
              <b>Protein</b> {Math.round(labelObject.protein)}g
            </td>
            <td style={{ textAlign: "right" }}>
              <b>{dailyValuePercentages.protein}%</b>
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "12px" }}>
              Vitamin D {Math.round(labelObject.vitaminD)}mcg
            </td>
            <td style={{ textAlign: "right", fontSize: "12px" }}>
              {dailyValuePercentages.vitaminD}%
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "12px" }}>
              Calcium {Math.round(labelObject.calcium)}mg
            </td>
            <td style={{ textAlign: "right", fontSize: "12px" }}>
              {dailyValuePercentages.calcium}%
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "12px" }}>
              Iron {Math.round(labelObject.iron)}mg
            </td>
            <td style={{ textAlign: "right", fontSize: "12px" }}>
              {dailyValuePercentages.iron}%
            </td>
          </tr>
          <tr>
            <td style={{ fontSize: "12px" }}>
              Potassium {Math.round(labelObject.potassium)}mg
            </td>
            <td style={{ textAlign: "right", fontSize: "12px" }}>
              {dailyValuePercentages.potassium}%
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};
