import {
  Badge,
  Button,
  Card,
  Container,
  HoverCard,
  Modal,
  ScrollArea,
  SimpleGrid,
  Table,
  Text,
} from "@mantine/core";

import { mapNutrientsAndReturnAttributes } from "../utils/getFoodAttributes";
import { emojiByFoodCategory } from "../utils/iconHelper";
import { FoodFacts } from "./FoodFacts";
import { useState } from "react";

export default function SearchResults({
  searchResults,
  searchResultsCount,
  setSearchResultsCount,
}) {
  const displayedSearchResults = searchResults.slice(0, searchResultsCount);
  const [opened, setOpened] = useState(false);
  const [selectedRowItem, setSelectedRowItem] = useState("");

  function showMoreResults() {
    setSearchResultsCount(searchResultsCount + 10);
  }

  return (
    <>
      <>
        <SimpleGrid
          cols={4}
          spacing="xs"
          verticalSpacing="xs"
          breakpoints={[
            { maxWidth: 980, cols: 3, spacing: "md" },
            { maxWidth: 755, cols: 2, spacing: "sm" },
            { maxWidth: 600, cols: 1, spacing: "sm" },
          ]}
        >
          {displayedSearchResults.map((item) => {
            const attributeBadges = mapNutrientsAndReturnAttributes(
              item.foodNutrients
            );

            return (
              <div>
                <Card
                  shadow="sm"
                  radius="md"
                  withBorder
                  key={item.fdcId}
                  onClick={() => {
                    setOpened(true);
                    setSelectedRowItem(item);
                  }}
                >
                  <p>
                    {item.brandName} <i>{item.brandOwner}</i>
                  </p>
                  <p>{item.description}</p>
                  <p style={{ textAlign: "right" }}>
                    {emojiByFoodCategory(item.foodCategory)}
                  </p>
                  <Badge color="blue" variant="light">
                    {item.foodCategory.length > 15
                      ? item.foodCategory.substring(0, 15) + "..."
                      : item.foodCategory}
                  </Badge>{" "}
                  <p>{attributeBadges}</p>
                  <HoverCard>
                    <HoverCard.Target>
                      <Button compact variant="outline" size="xs">
                        Add
                      </Button>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <Text size="sm">🚧🚧🚧</Text>
                    </HoverCard.Dropdown>
                  </HoverCard>
                </Card>
              </div>
            );
          })}
          <Modal opened={opened} onClose={() => setOpened(false)}>
            <FoodFacts selectedRowItem={selectedRowItem} />
          </Modal>
        </SimpleGrid>
      </>

      {searchResultsCount < searchResults.length && (
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            style={{ margin: "5px 0px 5px 0px" }}
            onClick={showMoreResults}
          >
            Show More
          </Button>
        </Container>
      )}
    </>
  );
}
