export function emojiByFoodCategory(item) {
  const foodIcons = {
    Alcohol: "🍸",
    "All Noodles": "🍜",
    "Bacon, Sausages & Ribs": "🥓",
    Baking: "🍰",
    "Baking Accessories": "🍰",
    "Baking Additives & Extracts": "🍰",
    "Baking Decorations & Dessert Toppings": "🍰",
    "Biscuits/Cookies": "🍪",
    Bread: "🍞",
    "Nutrition bars": "🍫",
    Snacks: "🍫",
    "Bread & Muffin Mixes": "🍞",
    "Breads & Buns": "🍞",
    "Breakfast Drinks": "🧃",
    "Breakfast Sandwiches, Biscuits & Meals": "🥐",
    "Butter & Spread": "🧈",
    "Cake, Cookie & Cupcake Mixes": "🍰🍪🧁",
    "Cakes, Cupcakes, Snack Cakes": "🍰🍪🧁",
    Candy: "🍬",
    "Canned & Bottled Beans": "🥫",
    "Canned Condensed Soup": "🥫",
    "Canned Fruit": "🥫🍍",
    "Canned Meat": "🥫🍖",
    "Canned Seafood": "🥫🦐",
    "Canned Soup": "🥫",
    "Canned Tuna": "🥫🐟",
    "Canned Vegetables": "🥫🥬",
    Cereal: "🥣",
    Cheese: "🧀",
    "Chewing Gum & Mints": "🍬",
    "Chili & Stew": "🍜",
    "Chips, Pretzels & Snacks": "🥨",
    Chocolate: "🍫",
    Coffee: "☕",
    "Cooked & Prepared": "👩‍🍳",
    "Cookies & Biscuits": "🍪",
    "Crackers & Biscotti": "🥮",
    Cream: "🥛",
    "Croissants, Sweet Rolls, Muffins & Other Pastries": "🥐🧁",
    "Crusts & Dough": "🫓",
    "Deli Salads": "🥗",
    "Dips & Salsa": "💃",
    "Dough Based Products / Meals": "🌾",
    "Eggs & Egg Substitutes": "🥚",
    "Energy, Protein & Muscle Recovery Drinks": "💪",
    "Entrees, Sides & Small Meals": "🍲",
    "Fish & Seafood": "🐟🍤🍣",
    "Flavored Rice Dishes": "🍚",
    "Flavored Snack Crackers": "🥮",
    "Flours & Corn Meal": "🌾🌽",
    "French Fries, Potatoes & Onion Rings": "🍟",
    "Frozen Appetizers & Hors D'oeuvres": "🧊",
    "Frozen Bacon, Sausages & Ribs": "🥓🌭",
    "Frozen Bread & Dough": "🥖",
    "Frozen Breakfast Sandwiches, Biscuits & Meals": "🥯",
    "Frozen Dinners & Entrees": "🧊",
    "Frozen Fish & Seafood": "🐟🍤",
    "Frozen Fruit & Fruit Juice Concentrates": "🧃",
    "Frozen Pancakes, Waffles, French Toast & Crepes": "🥞🧇",
    "Frozen Patties and Burgers": "🍔",
    "Frozen Poultry, Chicken & Turkey": "🐔",
    "Frozen Prepared Sides": "🧊",
    "Frozen Sausages, Hotdogs & Brats": "🌭",
    "Frozen Vegetables": "🥦",
    "Fruit  Prepared/Processed": "🍓",
    "Fruit & Vegetable Juice, Nectars & Fruit Drinks": "🧃",
    "Gelatin, Gels, Pectins & Desserts": "🍮",
    "Granulated, Brown & Powdered Sugar": "🧊",
    "Gravy Mix": "",
    "Health Care": "⚕️",
    "Herbal Supplements": "🍃💊",
    "Herbs & Spices": "🍃🧂",
    "Herbs/Spices/Extracts": "🍃🧂",
    "Home Decoration & Supplies": "🧵",
    Honey: "🍯",
    "Ice Cream & Frozen Yogurt": "🍦",
    "Iced & Bottle Tea": "🧋",
    "Jam, Jelly & Fruit Spreads": "🍓🍞",
    "Ketchup, Mustard, BBQ & Cheese Sauce": "🍅",
    "Kitchen Supplies": "🍽️",
    "Liquid Water Enhancer": "💦",
    "Lunch Snacks & Combinations": "🥪",
    "Meal Replacement Supplements": "💊🥛",
    "Meat/Poultry/Other Animals  Prepared/Processed": "🥩",
    "Meat/Poultry/Other Animals  Unprepared/Unprocessed": "🥩",
    Media: "🎥",
    "Mexican Dinner Mixes": "🌮",
    Milk: "🥛",
    "Milk Additives": "🥛",
    Miscellanious: "🟢",
    "Non Alcoholic Beverages  Ready to Drink": "🥤",
    "Nut & Seed Butters": "🥜🧈",
    "Oils Edible": "🍶",
    "Oral Hygiene Products": "🪥",
    "Oriental, Mexican & Ethnic Sauces": "🥫",
    "Other Condiments": "🥫",
    "Other Cooking Sauces": "🥫",
    "Other Deli": "🥪",
    "Other Drinks": "🥤",
    "Other Frozen Desserts": "🍦",
    "Other Frozen Meats": "🥩",
    "Other Grains & Seeds": "🌽",
    "Other Meats": "🥩",
    "Other Snacks": "🍫",
    "Other Soups": "🍲",
    "Pancakes, Waffles, French Toast & Crepes": "🥞🧇",
    "Pasta by Shape & Type": "🍝",
    "Pasta Dinners": "🍝",
    "Pastry Shells & Fillings": "🥧",
    "Pepperoni, Salami & Cold Cuts": "🍖",
    "Pickles, Olives, Peppers & Relishes": "🥒🫒",
    Pizza: "🍕",
    "Pizza Mixes & Other Dry Dinners": "🍕",
    "Plant Based Milk": "🥛",
    "Plant Based Water": "💧",
    "Popcorn, Peanuts, Seeds & Related Snacks": "🍿🥜",
    "Poultry, Chicken & Turkey": "🐔",
    "Powdered Drinks": "🥤",
    "Pre-Packaged Fruit & Vegetables": "🥫",
    "Prepared Pasta & Pizza Sauces": "🍝",
    "Prepared Soups": "🍜",
    "Prepared Subs & Sandwiches": "🥪",
    "Prepared Wraps and Burittos": "🌯",
    "Puddings & Custards": "🍮",
    Rice: "🍚",
    "Salad Dressing & Mayonnaise": "🥗",
    "Sauces/Spreads/Dips/Condiments": "🥫",
    "Sausages, Hotdogs & Brats": "🌭",
    "Savoury Bakery Products": "🥐",
    "Seasoning Mixes, Salts, Marinades & Tenderizers": "🧂",
    "Snack, Energy & Granola Bars": "🍫",
    Soda: "🥤",
    "Specialty Formula Supplements": "💊",
    "Sport Drinks": "🥤",
    Stuffing: "",
    Sushi: "🍣",
    "Sweet Bakery Products": "🍰",
    Sweets: "🍬",
    "Syrups & Molasses": "🥞",
    "Tea Bags": "🫖",
    Tomatoes: "🍅",
    "Vegetable & Cooking Oils": "",
    "Vegetable and Lentil Mixes": "",
    "Vegetables  Prepared/Processed": "",
    "Vegetarian Frozen Meats": "🍃",
    Vitamins: "💊",
    Water: "💧",
    "Weight Control": "⚖️",
    "Wholesome Snacks": "🌾",
    Yogurt: "🍦",
  };
  return foodIcons[item];
}
