import axios from "axios";
import React, { useState } from "react";
import {
  Accordion,
  AppShell,
  Aside,
  Badge,
  Box,
  Button,
  Burger,
  Code,
  Container,
  Header,
  HoverCard,
  MediaQuery,
  Navbar,
  Text,
  // Textarea,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
// import { FoodFacts } from "./components/FoodFacts";
import SearchResults from "./components/SearchResults";
// import ThankYou from "./components/ThankYou";
import "./App.css";

function App() {
  const [searchTerm, setSearchTerm] = useState("");
  const [brandOwner, setBrandOwner] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [noSearchResults, setNoSearchResults] = useState(false);
  const [searchResultsCount, setSearchResultsCount] = useState(10);

  // const [feedback, setFeedback] = useState("");
  // const [successMessage, setSuccessMessage] = useState("");
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  //Search keywords
  function searchUsda() {
    const resultsLimit = 50;
    const dataTypeArray = ["Foundation", "Branded"];
    const dataType = dataTypeArray.join(",");
    const url = `https://api.nal.usda.gov/fdc/v1/foods/search?api_key=DEMO_KEY&query=${searchTerm}&brandOwner=${brandOwner}&dataType=${dataType}&pageSize=${resultsLimit}`;

    axios
      .get(url)
      .then((response) => {
        setSearchResults(response.data.foods);
        if (response.data.foods.length === 0) {
          setNoSearchResults(true);
        } else {
          setNoSearchResults(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function resetSearchResultsCount() {
    setSearchResultsCount(10);
  }

  // function handleFeedbackSubmit() {
  //   axios
  //     .post("http://localhost:5000/feedback", {
  //       feedback: feedback,
  //     })
  //     .then((response) => {
  //       setSuccessMessage(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  return (
    <>
      <AppShell
        styles={{
          main: {
            background:
              theme.colorScheme === "dark" ? theme.colors.dark[8] : "white",
          },
        }}
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        navbar={
          <Navbar
            className="App-Shell-Navbar"
            p="md"
            hiddenBreakpoint="sm"
            hidden={!opened}
            width={{ sm: 200, lg: 300 }}
          >
            <Accordion defaultValue="searchTips">
              <Accordion.Item value="searchTips">
                <Accordion.Control>Search Tips</Accordion.Control>
                <Accordion.Panel>
                  <Text size="sm">
                    <Code>" "</Code> to search for an exact phrase
                    <p>
                      <i>
                        <Code>"candy corn"</Code> matches foods that contain
                        "candy corn"
                      </i>
                    </p>
                    <p>
                      <Code>+</Code> to require words
                    </p>
                    <p>
                      <i>
                        <Code>+candy corn</Code> matches foods that have “candy”
                        and weighs foods that have “corn” higher.
                      </i>
                    </p>
                    <p>
                      <i>
                        <Code>+candy +corn</Code> matches foods that have both
                        “candy” and “corn”.
                      </i>
                    </p>
                    <p>
                      <Code>-</Code> to exclude words
                    </p>
                    <p>
                      <i>
                        <Code>corn -candy</Code> matches foods that have “corn”
                        but not “candy”.
                      </i>
                    </p>
                    <p>
                      <Code>*</Code> to match part of a word
                    </p>
                    <p>
                      <i>
                        <Code>*corn</Code> matches all foods with "corn” at the
                        end of their name.
                      </i>
                    </p>
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="attributes">
                <Accordion.Control>Attributes</Accordion.Control>
                <Accordion.Panel>
                  <Text size="sm">
                    <Badge color="pink" variant="light">
                      🧊High Sugar
                    </Badge>
                    <Text size="sm">
                      <i>&nbsp;&nbsp;&nbsp;&gt;= 13g</i>
                    </Text>
                    <Badge color="violet" variant="light">
                      🧊Low Sugar
                    </Badge>
                    <Text size="sm">
                      <i>&nbsp;&nbsp;&nbsp;&lt;= 2g</i>
                    </Text>
                    <Badge color="yellow" variant="light">
                      💪High-Protein
                    </Badge>
                    <Text size="sm">
                      <i>&nbsp;&nbsp;&nbsp;&gt;= 10g</i>
                    </Text>
                    <Badge color="grey" variant="light">
                      👙Low Carb
                    </Badge>
                    <Text size="sm">
                      <i>&nbsp;&nbsp;&nbsp;&lt;= 8g</i>
                    </Text>
                    <Badge color="orange" variant="light">
                      🏃High Carb
                    </Badge>
                    <Text size="sm">
                      <i>&nbsp;&nbsp;&nbsp;&gt;= 35g</i>
                    </Text>
                    <Badge color="green" variant="light">
                      ❤️Low Cholesterol
                    </Badge>
                    <Text size="sm">
                      <i>&nbsp;&nbsp;&nbsp;&lt;= 50g</i>
                    </Text>
                    <Badge color="gray" variant="light">
                      💔High Cholesterol
                    </Badge>
                    <Text size="sm">
                      <i>&nbsp;&nbsp;&nbsp;&gt;= 100g</i>
                    </Text>
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              <Accordion.Item value="roadmap">
                <Accordion.Control>Roadmap</Accordion.Control>
                <Accordion.Panel>
                  <Text size="sm">
                    <p>✅ Discover</p>
                    <p>⬜ Filter</p>
                    <p>⬜ Add to Pantry</p>
                    <p>⬜ Compare</p>
                    <p>⬜ Suggest & Swap</p>
                    <p>⬜ Purchase</p>
                  </Text>
                </Accordion.Panel>
              </Accordion.Item>
              {/* <Accordion.Item value="feedback">
                <Accordion.Control>Feedback</Accordion.Control>
                <Accordion.Panel>
                  {successMessage ? (
                    <ThankYou successMessage={successMessage} />
                  ) : (
                    <>
                      <Textarea
                        placeholder="Submit comments, questions, or suggestions here."
                        autosize
                        minRows={4}
                        onChange={(event) => setFeedback(event.target.value)}
                      />

                      <Button
                        style={{ margin: "5px 0px 5px 0px" }}
                        onClick={handleFeedbackSubmit}
                      >
                        Submit
                      </Button>
                    </>
                  )}
                </Accordion.Panel>
              </Accordion.Item> */}
            </Accordion>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                alignContent: "flex-end",
                flexGrow: 1,
              }}
            >
              <Text
                width={{ sm: 200, lg: 300 }}
                hiddenBreakpoint="sm"
                size="xs"
              >
                <p style={{ margin: "2px 0px 2px 5px" }}>
                  📊 Data Source:{" "}
                  <a href="https://fdc.nal.usda.gov/">USDA Food Data Central</a>
                </p>
                <p style={{ margin: "2px 0px 8px 5px" }}>
                  ⌨️ Created by:{" "}
                  <a href="https://twitter.com/aubthread">Aubrey Threadgill</a>
                </p>
              </Text>
            </div>
          </Navbar>
        }
        // aside={
        //   <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        //     <Aside
        //       className="App-Shell-Sidebar"
        //       p="md"
        //       hiddenBreakpoint="sm"
        //       width={{ sm: 350, lg: 400 }}
        //     >
        //       {selectedRowItem ? (
        //         <FoodFacts selectedRowItem={selectedRowItem} />
        //       ) : (
        //         <h1> </h1>
        //       )}
        //     </Aside>
        //   </MediaQuery>
        // }
        header={
          <Header height={60} p="md" className="App-Shell-Header">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                height: "100%",
              }}
            >
              <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Burger
                  opened={opened}
                  onClick={() => setOpened((o) => !o)}
                  size="sm"
                  color="white"
                  mr="xl"
                />
              </MediaQuery>
              <Text>
                <span style={{ whiteSpace: "nowrap" }}>
                  <span style={{ fontSize: "32px" }}>🍓</span>
                  Snack
                  <b>Facts</b>
                </span>
              </Text>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: "90%",
                }}
              >
                <a
                  href="https://www.twitter.com/aubthread"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ion-icon
                    className="twitter-icon"
                    style={{
                      fontSize: "25px",
                      margin: "-2px 10px",
                      color: "white",
                    }}
                    name="logo-twitter"
                  ></ion-icon>
                </a>
                <a
                  href="https://github.com/aubreythreadgill/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ion-icon
                    className="social-icon"
                    style={{
                      fontSize: "25px",
                      margin: "-2px 10px",
                      color: "white",
                    }}
                    name="logo-github"
                  ></ion-icon>
                </a>
              </div>
            </div>
          </Header>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            alignContent: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div className="searchInput" style={{ justifyContent: "flex-start" }}>
            <Text>
              <h3>🔍Discover</h3>
            </Text>

            <TextInput
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  searchUsda();
                  resetSearchResultsCount();
                }
              }}
              placeholder="e.g. chocolate bar, snack bar, sports drink, etc."
              label="Search any keywords"
              withAsterisk
              sx={{ width: "400px" }}
            />
            <TextInput
              onChange={(e) => setBrandOwner(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  searchUsda();
                  resetSearchResultsCount();
                }
              }}
              placeholder="e.g. Hershey, KIND, Gatorade, etc."
              label="Narrow results by brand (optional)"
              sx={{ width: "400px" }}
            />
            <Button
              onClick={() => {
                searchUsda();
                resetSearchResultsCount();
              }}
              color="green"
              style={{ margin: "5px 0px 5px 0px" }}
            >
              Search
            </Button>
            <Box size="lg" style={{ textAlign: "left" }}>
              <Text size="sm" style={{ fontWeight: "500" }}>
                Or choose from a curated food group:
              </Text>
              <HoverCard shadow="md">
                <HoverCard.Target>
                  <Button compact variant="light" style={{ margin: "5px" }}>
                    💪Protein Bars
                  </Button>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text size="sm">🚧🚧🚧</Text>
                </HoverCard.Dropdown>
              </HoverCard>
              <HoverCard shadow="md">
                <HoverCard.Target>
                  <Button compact variant="light" style={{ margin: "5px" }}>
                    🍿Chips & Popcorn
                  </Button>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text size="sm">🚧🚧🚧</Text>
                </HoverCard.Dropdown>
              </HoverCard>
              <HoverCard shadow="md">
                <HoverCard.Target>
                  <Button compact variant="light" style={{ margin: "5px" }}>
                    🍾Seltzers (Alcoholic)
                  </Button>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text size="sm">🚧🚧🚧</Text>
                </HoverCard.Dropdown>
              </HoverCard>
              <HoverCard shadow="md">
                <HoverCard.Target>
                  <Button compact variant="light" style={{ margin: "5px" }}>
                    🍫Chocolatey Snacks
                  </Button>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text size="sm">🚧🚧🚧</Text>{" "}
                </HoverCard.Dropdown>
              </HoverCard>
            </Box>
          </div>
          <div className="searchInput">
            <Box>
              <HoverCard>
                <HoverCard.Target>
                  <Text>
                    <h3>➖Filter</h3>
                  </Text>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Text size="sm">🚧🚧🚧</Text>
                </HoverCard.Dropdown>
              </HoverCard>
              <Text>Any dietary restrictions?</Text>
              <Button
                compact
                variant="light"
                color="violet"
                style={{ margin: "5px" }}
              >
                Gluten-Free
              </Button>
              <Button
                compact
                variant="light"
                color="violet"
                style={{ margin: "5px" }}
              >
                Vegetarian
              </Button>
              <Button
                compact
                variant="light"
                color="violet"
                style={{ margin: "5px" }}
              >
                Vegan
              </Button>
              <Button
                compact
                variant="light"
                color="violet"
                style={{ margin: "5px" }}
              >
                Pescatarian
              </Button>
            </Box>
            <Box>
              <Text>Any food allergies or sensitivities?</Text>
              <Button
                compact
                variant="light"
                color="red"
                style={{ margin: "5px" }}
              >
                🌰Tree Nuts
              </Button>
              <Button
                compact
                variant="light"
                color="red"
                style={{ margin: "5px" }}
              >
                🥜Peanuts
              </Button>
              <Button
                compact
                variant="light"
                color="red"
                style={{ margin: "5px" }}
              >
                🐚Shellfish
              </Button>
              <Button
                compact
                variant="light"
                color="red"
                style={{ margin: "5px" }}
              >
                🥛Cow's Milk
              </Button>
              <Button
                compact
                variant="light"
                color="red"
                style={{ margin: "5px" }}
              >
                🥚Eggs
              </Button>
            </Box>
          </div>
        </div>
        <Container size="lg" style={{ margin: "10px 0px 5px 0px" }}>
          {noSearchResults ? (
            <Text size="sm" style={{ fontWeight: "500" }}>
              No results found. Try refining your search.
            </Text>
          ) : (
            <SearchResults
              searchResults={searchResults}
              searchResultsCount={searchResultsCount}
              setSearchResultsCount={setSearchResultsCount}
            />
          )}
        </Container>
      </AppShell>
    </>
  );
}

export default App;
