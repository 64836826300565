import { Badge, Card, Group, ScrollArea, Text } from "@mantine/core";
import { mapNutrientsAndReturnLabel } from "../utils/labelHelper";
import { mapNutrientsAndReturnAttributes } from "../utils/getFoodAttributes";
import { emojiByFoodCategory } from "../utils/iconHelper";

export function FoodFacts({ selectedRowItem }) {
  const label = mapNutrientsAndReturnLabel(selectedRowItem);
  const attributeBadges = mapNutrientsAndReturnAttributes(
    selectedRowItem.foodNutrients
  );
  const foodCategory = selectedRowItem.foodCategory;
  const description = selectedRowItem.description;
  const emoji = emojiByFoodCategory(foodCategory);

  return (
    <>
      <Card shadow="sm" p="sm" radius="md" withBorder>
        <Card.Section
          withBorder
          inheritPadding
          py="xs"
          className="label-header"
        >
          <Group position="apart">
            <Text
              weight={700}
              numberOfLines={1}
              sx={{
                height: "1.2em",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              style={{
                fontSize: "1.2em",
              }}
            >
              {description}
            </Text>
          </Group>
        </Card.Section>
        <ScrollArea style={{ height: 750 }}>
          <Text>
            <p>
              <b>Brand</b>: {selectedRowItem.brandName}{" "}
              <i>{selectedRowItem.brandOwner}</i>
            </p>
            <h1 style={{ margin: "-10px 0px 0px 0px" }}>{emoji}</h1>
            <Badge color="blue" variant="light">
              {foodCategory}
            </Badge>
            <p>{attributeBadges}</p>
            {label}
            <Text size="xs">
              <b>Ingredients</b>: {selectedRowItem.ingredients}
            </Text>
          </Text>
        </ScrollArea>
      </Card>
    </>
  );
}
