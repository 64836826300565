import { Badge } from "@mantine/core";

const nutrientIds = {
  calories: 1008,
  totalFat: 1004,
  saturatedFat: 1258,
  transFat: 1257,
  cholesterol: 1253,
  sodium: 1093,
  totalCarbohydrate: 1005,
  dietaryFiber: 1079,
  totalSugar: 2000,
  addedSugar: 1235,
  protein: 1003,
  vitaminD: 1110,
  calcium: 1087,
  iron: 1089,
  potassium: 1092,
};

export const mapNutrientsAndReturnAttributes = (nutrients) => {
  const labelObject = {
    calories: 0,
    totalFat: 0,
    saturatedFat: 0,
    transFat: 0,
    cholesterol: 0,
    sodium: 0,
    totalCarbohydrate: 0,
    dietaryFiber: 0,
    totalSugar: 0,
    addedSugar: 0,
    protein: 0,
    vitaminD: 0,
    calcium: 0,
    iron: 0,
    potassium: 0,
  };

  nutrients.map((item) => {
    const id = item.nutrientId;
    const amount = item.value;

    switch (id) {
      case nutrientIds.calories:
        labelObject.calories = amount;
        break;
      case nutrientIds.totalFat:
        labelObject.totalFat = amount;
        break;
      case nutrientIds.saturatedFat:
        labelObject.saturatedFat = amount;
        break;
      case nutrientIds.transFat:
        labelObject.transFat = amount;
        break;
      case nutrientIds.cholesterol:
        labelObject.cholesterol = amount;
        break;
      case nutrientIds.sodium:
        labelObject.sodium = amount;
        break;
      case nutrientIds.totalCarbohydrate:
        labelObject.totalCarbohydrate = amount;
        break;
      case nutrientIds.dietaryFiber:
        labelObject.dietaryFiber = amount;
        break;
      case nutrientIds.totalSugar:
        labelObject.totalSugar = amount;
        break;
      case nutrientIds.addedSugar:
        labelObject.addedSugar = amount;
        break;
      case nutrientIds.protein:
        labelObject.protein = amount;
        break;
      case nutrientIds.vitaminD:
        labelObject.vitaminD = amount;
        break;
      case nutrientIds.calcium:
        labelObject.calcium = amount;
        break;
      case nutrientIds.iron:
        labelObject.iron = amount;
        break;
      case nutrientIds.potassium:
        labelObject.potassium = amount;
        break;
      default:
        break;
    }
    return;
  });
  const highSugarThreshold = 13;
  const lowSugarThreshold = 2;
  const highProteinThreshold = 10;
  const lowCarbThreshold = 8;
  const highCarbThreshold = 35;
  const lowCholesterolThreshold = 50;
  const highCholesterolThreshold = 100;

  return (
    <>
      {labelObject.totalSugar >= highSugarThreshold && (
        <Badge color="pink" variant="light">
          🧊High Sugar
        </Badge>
      )}

      {labelObject.totalSugar <= lowSugarThreshold && (
        <Badge color="violet" variant="light">
          🧊Low Sugar
        </Badge>
      )}

      {labelObject.protein >= highProteinThreshold && (
        <Badge color="yellow" variant="light">
          💪High-Protein
        </Badge>
      )}

      {labelObject.totalCarbohydrate <= lowCarbThreshold && (
        <Badge color="grey" variant="light">
          👙Low Carb
        </Badge>
      )}
      {labelObject.totalCarbohydrate >= highCarbThreshold && (
        <Badge color="orange" variant="light">
          🏃High Carb
        </Badge>
      )}
      {labelObject.cholesterol <= lowCholesterolThreshold && (
        <Badge color="green" variant="light">
          ❤️Low Cholesterol
        </Badge>
      )}
      {labelObject.cholesterol >= highCholesterolThreshold && (
        <Badge color="gray" variant="light">
          💔High Cholesterol
        </Badge>
      )}
    </>
  );
};
